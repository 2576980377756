.highlight:not(.djs-connection) .djs-visual> :nth-child(1) {
  fill: green !important;
}


.modeler {
  flex: 1;
  display: flex;
  flex-direction: row;
}

#modeler-container {
  flex: 1;
}

.properties-panel {
  background: #ffffff;
  border-left: solid 1px #ccc;
  width: 40vh !important;
  height: 90vh;
  overflow-y: auto;

}

#properties-container {
  overflow-y: auto;

  border-top: solid 2px #CCC;
  background: #FAFAFA;

  padding: 5px;
  /* height: 200px; */
}

* {
  box-sizing: border-box;
}

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 !important;
}

.bjsl-button {
  position: fixed !important;
  left: 95% !important;
  width: 300px !important;
  background-color: #f5f5f5 !important;
  /* padding: 0px!important; */
  bottom: 10px !important;
}

.bjsl-button-error {
  color: red !important;
}

.bjsl-button-success {
  color: #52b415 !important;
}

.bjsl-button-warning {
  color: #f7c71a !important;
}

.bts-toggle-mode {
  cursor: pointer;
  position: fixed !important;
  top: 97% !important;
  transform: translate(-80%) !important;
  left: 88% !important;

  background-color: #f5f5f5 !important;
  /* background-color: var(--token-simulation-silver-darken-94, #f5f5f5)!important; */
  border-radius: 10px !important;
  padding: 6px;
  font-size: 16px;
  color: #212121 !important;
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
}


.formEditor {
  max-width: 100%;
  margin-top: 10px;
}

.fjs-editor-container .fjs-children {
  min-height: 85vh !important;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  overflow: hidden !important;
}

.ant-drawer-body {
  overflow: hidden !important;
}

.codePre {
  height: 100% !important;
  margin-bottom: 0 !important;

}

.btnPopover.ant-btn {
  padding: 0 !important;
}

.ant-popover-placement-right {
  padding-left: 0 !important;
}

.formView .ant-card-body {
  padding: 0 !important;
}

.HomePageMain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.mainContainerBtn {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.containerBtn {
  background-color: #edecec;
  border: solid 5px #E9E9E9;
  border-radius: 5px;
  width: 300px;
  height: 400px;
  justify-content: space-between;
}

.textLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #223366;
}

.textLogoHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 8px;
  color: #223366;
}

.BpmnGif {
  margin-top: 15px;
  border: solid 5px #E9E9E9;
  border-radius: 5px;
  width: 50%;
  height: 45%;
}

.containerGif {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contentDmn {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modelerDmn {
  width: 100%;
  height: 90vh;
}

.dmn-js-parent {
  position: relative !important;
}

#properties .dpp-properties-panel {
  background: #f8f8f8;
  border-left: solid 1px #ccc;
  height: 100%;
  width: 400px;
}

#properties.hidden {
  display: none;
}

.tjs-container,
.dmn-literal-expression-container>.viewer-container {
  padding: 10px;
}

.ant-select-selection-placeholder {
  width: 100;
  color: #212121 !important;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.closeBtnForTab {
  color: silver;
  background-color: #fff;
  padding: 10px;
  padding-left: 0;
  padding-bottom: 12px;
  border: 1px solid rgb(224, 224, 224);
  border-left: none;
  border-radius: 0px 8px 0 0;
}

.closeBtnForTab:hover {
  color: #212121;
}

.sfariTab {
  position: relative;
  bottom: 14px;
}
.react-tabs__tab-list {
  border-bottom: 1px solid rgb(224, 224, 224);
  margin: 0;
  padding: 0;
  /* height: 38.750px; */
  /* overflow: auto;
  overflow-y: hidden;
  white-space: nowrap; */
}
.react-tabs__tab {
  color: gray;
  border-bottom: 1px solid rgb(224, 224, 224);
  display: inline-block;
  background: #fff;
  border: 1px solid transparent;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 10px 10px 20px;
  cursor: pointer;
  border-color: rgb(224, 224, 224);
  border-right: none;
  border-radius: 8px 0px 0 0;
  font-weight: lighter;
  width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: clip;
}


.react-tabs__tab--selected {
  border-bottom: 1px solid rgb(224, 224, 224);
  background: #fff;
  border-color: rgb(224, 224, 224);
  font-weight: 600;
  color: blue;
  border-radius: 8px 0px 0 0;
}

.react-tabs__tab--disabled {
  color: gray;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

/* .react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
} */

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.BpmnModeler {
  height: 90vh;
  width: 100%;
  overflow: hidden;
}

.DmnModeler {
  height: 90vh;
  width: 100%;
  overflow: hidden;
}

.FormModeler {
  height: 90vh;
  width: 100%;
  overflow: hidden;
}

.fjs-powered-by .fjs-form-field {
  z-index: 200 !important;
}

.ant-menu-item-selected {
  background-color: none !;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  /* text-align: center; */
  padding: 20px;
}

.tblDeployFiles {
  max-width: 190px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.nyanCat {
  transform: scale(-1, 1);
}
.scrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(196, 196, 196);
}

.scrollable::-webkit-scrollbar-button {
  display: none;
}
.djs-popup-body>div.group{
  display: flex !important;
}
.entry{
  width: 40px;
  height: 40px;
}
@media (max-height: 1203px) {
  .properties-panel {
    height: 88vh;
  }

  .BpmnModeler {
    height: 89vh;
  }

  .DmnModeler {
    height: 88vh;
  }

  .FormModeler {
    height: 89vh;
  }
}

@media (max-height: 1100px) {
  .properties-panel {
    height: 88vh;
  }

  .BpmnModeler {
    height: 88vh;
  }

  .bts-toggle-mode {
    top: 96% !important;
  }

  .DmnModeler {
    height: 87vh;
  }

  .FormModeler {
    height: 88vh;
  }
}

@media (max-height: 1000px) {
  .properties-panel {
    height: 87vh;
  }

  .BpmnModeler {
    height: 87vh;
  }

  .DmnModeler {
    height: 87vh;
  }

  .FormModeler {
    height: 87vh;
  }
}

@media (max-height: 900px) {
  .properties-panel {
    height: 86vh;
  }

  .BpmnModeler {
    height: 86vh;
  }

  .DmnModeler {
    height: 86vh;
  }

  .FormModeler {
    height: 86vh;
  }
}

@media (max-height: 800px) {
  .properties-panel {
    height: 84vh;
  }

  .BpmnModeler {
    height: 84vh;
  }

  .DmnModeler {
    height: 84vh;
  }

  .FormModeler {
    height: 84vh;
  }
}

@media (max-height: 700px) {
  .properties-panel {
    height: 83vh;
  }

  .BpmnModeler {
    height: 83vh;
  }

  .DmnModeler {
    height: 83vh;
  }

  .FormModeler {
    height: 83vh;
  }
}

@media (max-width:2309px) {
  .bjsl-button {
    left: 95% !important;
  }

  .bts-toggle-mode {
    left: 85% !important;
  }
}

@media (max-width:1700px) {
  .bjsl-button {
    left: 95% !important;
  }

  .bts-toggle-mode {
    left: 80% !important;
  }
}

@media (max-width:1140px) {
  .bjsl-button {
    left: 95% !important;
  }

  .bts-toggle-mode {
    left: 70% !important;
  }
}